/* Login.css */

  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .login-paper {
    padding: 20px;
    width: 100%;
    max-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-spacing {
    margin-top: 20px;
  }
  
  .login-center-text {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 8px;
    color: black;
  }
  
  /* Customize input styles */
  .login-input {
    width: 100%;
    margin-bottom: 15px;
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }

  .mobile-no {
    color: black !important;
    font-weight: 600 !important;
  }

  
  /* Customize button styles */
  .login-button {
    margin-top: 20px !important;
    background-color: #1976D2 !important;
  }
  
  .login-button:disabled {
    background-color: #ddd !important;
    cursor: not-allowed;
  }
  
  .login-button:hover:enabled {
    background-color: #115293 !important;
  }
  